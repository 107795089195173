<template>
  <b-row>
    <b-col lg="4" md="4" v-if="ticket">
      <b-card>
        <b-row>
          <b-col>
            <div class="d-flex">
              <feather-icon icon="UserIcon" size="19" class="text-primary" />
              <h4 class="mb-0 ml-50 text-primary">
                {{ $t("Destek Bilgileri") }}
              </h4>
            </div>
          </b-col>
          <b-col>
            <b-badge
              :variant="ticket.ticketOncelikTuru == 2 ? 'info' : 'danger'"
              class="float-right badge"
            >
              <span>{{ resolveOncelikTur(ticket.ticketOncelikTuru) }}</span>
            </b-badge>
          </b-col>
        </b-row>

        <b-col class="mt-2">
          <table class="mt-xl-0 w-100">
            <tr>
              <th class="pb-50">{{ $t('Kayıt No') }}</th>
              <td class="pb-50">
                {{ ticket.sKayitNo }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">{{ $t('Departman') }}</th>
              <td class="pb-50">
                {{ resolveDepartmanTur(ticket.departmentTuru) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">{{ $t('Konu') }}</th>
              <td class="pb-50">
                {{ ticket.sKonu }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">{{ $t('Uzak Destek Bilgisi') }}</th>
              <td class="pb-50">
                {{ ticket.sUzakDestekBilgisi }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">{{ $t('İlgili Kişi') }}</th>
              <td class="pb-50">
                {{ ticket.sIlgiliKisi }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">{{ $t('Telefon') }}</th>
              <td class="pb-50">
                {{ ticket.sTelefon }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">{{ $t('E-Posta') }}</th>
              <td class="pb-50">
                {{ ticket.sEPosta }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">{{ $t('Oluşturma Tarihi') }}</th>
              <td class="pb-50">
                {{ formatDateToDMYT(ticket.dtOlusTarih) }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-card>
    </b-col>
    <b-col cols="12" lg="8" md="8">
      <b-card v-if="!ticketDetayList.length">
        <b-row>
          <b-col>
            <div class="d-flex">
              <feather-icon icon="XIcon" size="19" class="text-warning" />
              <h6 class="mb-0 ml-50 text-warning">{{ $t('Destek detayı bulunamadı') }}</h6>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        :key="detay.id"
        v-for="detay in ticketDetayList"
        v-else
      >
        <b-row v-b-toggle="`collapse-${detay.id}`">
          <b-col>
            <div class="d-flex">
              <feather-icon icon="UserIcon" size="19" class="text-primary" />
              <h6 class="mb-0 ml-50 text-primary">
                {{ detay.isim }}
              </h6>
            </div>
          </b-col>
          <b-col>
            <span class="float-right">
              <feather-icon icon="ClockIcon" class="mr-50" />
              {{ formatDateToDMYT(detay.dtOlusTarih) }}
            </span>
          </b-col>
        </b-row>
        <b-collapse :id="`collapse-${detay.id}`">
          <b-card>
            <div class="p-2 bg-light-secondary rounded">
               {{ detay.sAciklama }}
            </div>
            <b-img @click="showModal(detay.id)" v-if="detay.sDosyaUrl" class="mt-2" height="270" width="400" :src="detay.sDosyaUrl" />
            <b-modal
              :ref="`modalimage${detay.id}`"
              title="Resim"
              ok-title="Tamam"
              cancel-title="Kapat"
              cancel-variant="outline-secondary"
              no-close-on-backdrop
              centered
              size="lg"
              v-if="detay.sDosyaUrl"
            >
              <b-img
                class="mt-2 custom-support-img"
                :src="detay.sDosyaUrl"
              />
            </b-modal>
          </b-card>
        </b-collapse>
      </b-card>
      <b-button v-if="ticket.isActive" v-b-toggle="'collapse-cevap'">{{ $t('Yeni Cevap Ekle') }} </b-button>
        <b-collapse id="collapse-cevap">
          <b-card>
          <b-row class="mt-2">
            <b-col cols="12" md="8" lg="8">
              <b-form-group :label="$t('Açıklama')" label-for="sAciklama">
                <b-form-textarea
                  id="textarea-sAciklama"
                  :placeholder="$t('Açıklama')"
                  v-model="cevap.sAciklama"
                  rows="4"
                />
                <b-button :disabled="$store.getters.getLoader" v-b-toggle="'collapse-cevap'" @click="save" class="mt-2" variant="warning">{{ $t('Gönder') }}</b-button>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="4">
              <label for="file">{{ $t('Dosya') }}</label>
              <b-form-file v-model="cevap.file" id="file" ref="file-input" accept="image/*" />
            </b-col>
          </b-row>
          </b-card>
        </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import { formatDateToDMYT, apiToDate } from '@core/utils/filter';
import AlertService from '@/common/alert.service'
import StaticOptions from '@/common/options/StaticOptions';
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  data() {
    return {
      ticket: {},
      ticketDetayList: [],
      cevap: {
        sAciklama: '',
        file: null,
      },
      form: new FormData(),
    }
  },

  methods: {
    fetchTicket() {
      const paramId = this.$route.params.detailId;
      if (paramId !== -1) {
        this.$store.dispatch('ticketCustomer/fetchTicket', paramId)
          .then(response => {
            if (response.statusCode === 200) {
              for (const prop in response.result) {
                if (response.result[prop] === 'null') {
                  response.result[prop] = '';
                }
              }
              response.result.dtOlusTarih = apiToDate(response.result.dtOlusTarih);
              this.ticket = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },
    save() {
      if (this.cevap.sAciklama === '') {
        AlertService.error(this, 'Açıklama giriniz.')
        return;
      }
      this.form.set('ticketId', this.ticket.id);
      this.form.set('sAciklama', this.cevap.sAciklama);
      this.form.set('file', this.cevap.file);
      this.$store.dispatch('ticketCustomer/sendAnswer', this.form)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
            this.fetchTicketDetays();
            this.cevap.file = null;
            this.cevap.sAciklama = null;
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
    showModal(detayId) {
      const modal = `modalimage${detayId}`
      this.$refs[modal][0].show()
    },
    resolveDepartmanTur(tur) {
      const result = StaticOptions.departmentTuruOptions.find(x => x.value === tur);
      return result ? result.label : ''
    },

    resolveOncelikTur(tur) {
      return tur === 1 ? 'Acil' : 'Normal'
    },
    formatDateToDMYT,
    apiToDate,
    fetchTicketDetays() {
      const id = this.$route.params.detailId;
      this.$store.dispatch('ticketCustomer/fetchTicketDetaylar', id)
        .then(response => {
          if (response.statusCode === 200) {
            response.result.forEach(x => this.getdetayIsim(x))
            this.ticketDetayList = response.result;
          }
        })
    },
    getdetayIsim(detay) {
      const isim = detay.sIlgiliKullanici ? detay.sIlgiliKullanici : detay.sIlgiliPersonel;
      detay.isim = isim;
      return detay;
    },
  },

  mounted() {
    this.fetchTicket();
    this.fetchTicketDetays();
  },
}
</script>

<style scoped>
:focus {
  outline: 0 !important;
}
.custom-support-img {
  width: 100%;
  height: auto;
}
</style>
